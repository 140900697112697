import {timeFormat} from "@custom";
export const tableColumn = [
    {
        prop: "innerMap.repairNo",
        label: "维修编号",
    },
    {
        prop: "innerMap.repairDetail",
        label: "维修内容"
    },
    {
        prop: "innerMap.repairType",
        label: "类型",
        // width: 100,
    },
    {
        prop: "innerMap.repairName",
        label: "报修人",
        // width: 80,
    },
    {
        prop: "innerMap.createTime",
        label: "报修时间",
        // width: 100,
        // render: (h, { row }) => {
        //     const {createTime} = row;
        //     return createTime ? timeFormat(new Date(createTime)) : "";
        // }
    },
    {
        prop: "innerMap.repairProject",
        label: "房间"
    },
    {
        prop: "innerMap.repairMan",
        label: "维修人",
        // width: 80,
    },
    {
        prop: "innerMap.commentdate",
        label: "维修时间",
        // width: 100,
        render: (h, { row }) => {
            const {commentdate} = row;
            return commentdate ? timeFormat(new Date(commentdate)) : "";
        }
    },
    {
        prop: "innerMap.dealState",
        label: "跟进状态",
        // width: 100,
    },
    {
        prop: "innerMap.confirmor",
        label: "确认人",
        // width: 80,
    },
];